import Separator from 'components/landing/Separator'
import styled from 'styled-components'
import DpcCards from 'components/dpc/DpcCards'
import React from 'react'
import { colors, sizes } from 'theme'
import {
  CheckList,
} from 'components/atoms'
import calendarImg from 'assets/images/dpc/calendar.svg'
import notesImg from 'assets/images/dpc/notes.svg'
import { PLAY_STORE_APP_URL, APPLE_STORE_APP_URL } from 'constants/link'
import { PageMainWrapper } from 'components/dpc/style'
import { downloadRules } from 'utils/file'
import StatisticsBanner from 'components/dpc/StatisticsBanner'
import DpcLayout from 'components/layouts/DpcLayout'

const Training = () => (
  <DpcLayout>
    <Wrapper>
      <h1 className="text-heading">Les parcours de formation</h1>
      <p className="text-content introduction">
        Le parcours de formation
        <em>
          {' '}
          &quot;Repérer le risque iatrogène et optimiser les interventions pharmaceutiques&quot;
          {' '}
        </em>
        vous permet de revoir progressivement 15 classes de médicaments les plus courantes et de
        vérifier que vous maîtrisez les données les plus actuelles pour chaque classe.
      </p>
      <p className="text-content introduction">
        Le parcours de formation DPC vous propose également une initiation aux
        <em> &quot;Interventions pharmaceutiques&quot; </em>
        (IP) que vous pourrez réaliser autour des classes de médicaments revues lors des Quiz Défimédoc.
      </p>
      <DpcCards />
      <Separator />
      <section className="in-details">
        <h1 className="text-heading">La formation en détail</h1>
        <h2 className="text-subheading">Public et prérequis</h2>
        <CheckList items={ prerequisites } />
      </section>
      <aside>
        <h2 className="text-subheading">Les objectifs</h2>
        <p className="text-content">
          La formation &quot;Repérer le risque iatrogène et optimiser les interventions pharmaceutiques&quot; a pour finalité la mise à jour des connaissances sur le médicament en tenant compte des
          dernières données de la science (pharmacovigilance, alertes ANSM, nouvelles recommandations HAS...).
        </p>
        <p className="text-content">
          A l&apos;issue de la formation, l’apprenant saura :
        </p>
        <CheckList
          items={ objectives }
        />
      </aside>
      <section className="details-grid">
        <div className="duration">
          <h2 className="text-subheading">Durée de la formation</h2>
          <p className="text-content">
            La durée de la formation est de 8h pour l&apos;Année 1 et 9h pour l&apos;Année2. La formation est délivrée progressivement. Elle ne peut
            pas être terminée avant le délai prévu de 4 mois.
          </p>
          <ul className="text-content">
            <li>
              <div>
                <em>Unité 1 : </em>
                Test pré-formation de 15 minutes.
              </div>
            </li>
            <li>
              <div>
                <em>Unité 2 : </em>
                Quiz d’apprentissages + lecture des Fiches l’Essentiel et validation des Quiz.
              </div>
            </li>
            <li>
              <div>
                <em>Unité 3 : </em>
                Rappels sur la rédaction des Interventions Pharmaceutiques, y compris sur le site Act-IP
                proposé par la SFPC (Société Française de Pharmacie Clinique). Chaque apprenant doit déposer sur son
                Espace apprenant 1 à 3 Interventions Pharmaceutiques pendant la durée de sa formation, à titre
                d&apos;exemples. Les IP sont anonymisées et certaines seront mises à disposition des stagiaires à titre
                d&apos;exemple.
              </div>
            </li>
            <li>
              <div>
                <em>Unité 4 : </em>
                Test post-formation de 15 minutes.
              </div>
            </li>
          </ul>
          <p className="text-content bold-italic">
            Le temps passé sur chaque session de Quiz est enregistré directement dans l&apos;application pour chaque
            stagiaire. Un cumul du temps passé est transmis aux organismes financeurs.
          </p>
        </div>
        <Calendar>
          <img src={ calendarImg } />
        </Calendar>
        <div className="delay">
          <h2 className="text-subheading">Délai d&apos;accès</h2>
          <p className="text-content">
            Les prochaines sessions de formation démarrent le
            <em> 6 janvier 2025</em>
            . Il est possible de s&apos;inscrire jusqu&apos;au
            <em> 28 Décembre 2024</em>
            , sous réserve de places libres dans la session en cours.
          </p>
          <p className="text-content">
            L&apos;inscription doit être validée sur le site de l&apos;ANDPC pour les pharmaciens titulaires.
          </p>
          <p className="text-content">
            <em>L&apos;accès aux Quiz </em>
            sera ouvert le premier jour de la session, dès le Pré-Test terminé.
          </p>
        </div>
        <div className="means">
          <h2 className="text-subheading">Moyens</h2>
          <p className="text-content">
            Le contenu pédagogique (Fiches l&apos;Essentiel, Quiz, Scores, Histogrammes de positionnement, dialogue
            avec le responsable pédagogique et entre apprenants) est disponible
            <em> via l’application mobile Défimédoc.</em>
          </p>
          <p className="text-content">
            Le téléchargement de l&apos;application mobile Défimédoc se fait sur
            {' '}
            <a
              href={ APPLE_STORE_APP_URL }
              rel="noreferrer"
              target="_blank"
            >
              Applestore
            </a>
            {' '}
            ou
            {' '}
            <a
              href={ PLAY_STORE_APP_URL }
              rel="noreferrer"
              target="_blank"
            >
              Playstore
            </a>
            {' '}
            . Le bon fonctionnement de l&apos;application nécessite une version minimum iOs11 (iphone 6 et suivant) ou un
            téléphone fonctionnant sous android.
          </p>
          <p className="text-content">
            L&apos;inscription à l&apos;application nécessite d’être professionnel de santé ou étudiant en santé.
            L&apos;accès au compte est strictement individuel.
          </p>
          <p className="text-content">
            La connexion à l&apos;application mobile est possible 24h/24h 7 jours sur 7 grâce à un identifiant de
            connexion et un mot de passe.
          </p>
          <p className="text-content">
            L’apprenant peut organiser son déroulé de formation à sa guise en fonction de ses contraintes personnelles.
          </p>
          <p className="text-content">
            <em>
              Pour valider sa formation
              , l&apos;apprenant doit avoir fait la totalité des Quiz
              {' '}
            </em>
            proposés.
          </p>
        </div>
        <Notes>
          <img src={ notesImg } />
        </Notes>
        <div className="supports">
          <h2 className="text-subheading">Supports pédagogiques</h2>
          <p className="text-content">
            <em>Les fiches &quot;L&apos;essentiel&quot; </em>
            en 10 points sont disponibles dans l&apos;onglet &quot;Essentiels&quot; de l&apos;application et
            consultables en ligne.
            Elles sont rédigées par des Dr en pharmacie à partir des sites officiels : Haute Autorité de Santé, ansm,
            medicaments.gouv.fr, pharmacologie.com... et selon le Cahier des charges des Fiches Défimédoc. Ces fiches sont mises à jour en temps réel en fonction de l&apos;actualité et au minimum une fois par an.
          </p>
          <p className="text-content">
            Chaque support comporte les points suivants :
            <em>
              {' '}
              mode d&apos;action, posologies, interactions, contre-indications, effets indésirables, place du
              médicament chez l&apos;enfant, chez la femme enceinte et chez la personne agée.
            </em>
          </p>
          <p className="text-content">
            Chaque fiche comporte
            <em> une bibliographie </em>
            avec liens cliquables en ligne.
          </p>
          <p className="text-content bold-italic">
            Les fiches au format pdf sont envoyés par mail à la fin de la formation, une
            fois le Test post-formation terminé.
          </p>
        </div>
        <div className="tests">
          <h2 className="text-subheading">Tests pré et post-formation</h2>
          <p className="text-content">
            Un test portant sur des cas pratiques est proposé avant le début de la formation et sera proposé de nouveau
            après la formation.
          </p>
          <p className="text-content bold-italic">
            Le taux d’amélioration sera un reflet des acquis de compétences au cours de la formation.
          </p>
        </div>
        <div className="evaluation">
          <h2 className="text-subheading">Méthodes et modalités d&apos;évaluation</h2>
          <p className="text-content">
            <em className="bold-italic">
              L&apos;évaluation est continue.
              <br />
            </em>
            Les scores apparaissent tout au long de la formation, à chaque Quiz.
            Chaque Quiz peut être refait de façon à améliorer son score.
            Le score cumulé apparait sous forme de graphique mois par mois.
          </p>
        </div>
        <div className="prices">
          <h2 className="text-subheading">Tarifs</h2>
          <p className="text-content">
            <em>Pour les pharmaciens titulaires</em>
            <span> disposant d&apos;une enveloppe budgétaire suffisante auprès de l&apos;ANDPC, le reste à charge est de 80€ pour la formation Année 1 (8 heures) et de 90€ pour la formation Année 2 (9 heures).</span>
          </p>
          <p className="text-content">
            <em>L’indemnisation ANDPC du pharmacien titulaire</em>
            <span> est de 220 € pour la formation Année 1 (8 heures) et de 247,50 € pour la formation Année 2 (9 heures).</span>
          </p>
          <p className="text-content">
            <em>Pour les salariés de l&apos;officine</em>
            <span>, le prix de la formation est de 300 € TTC pour la formation Année 1 (8 heures) et 337,50 € pour la formation Année 2 (9 heures).</span>
          </p>
          <p className="text-content">
            L’employeur peut faire une demande de prise en charge auprès de l&apos;OPCO EP: se renseigner auprès de l&apos;OPCO EP pour connaître votre éligibilité.
          </p>
          <p className="text-content">
            La formation comprends l&apos;accès à l&apos;application mobile, aux supports pédagogique et technique, et aux données de suivi pédagogique du 6 janvier 2025 au 2 mai 2025, ainsi que la mise à disposition des supports pédagogiques en fin de formation (doc pdf).
          </p>
        </div>
        <div className="accessibility">
          <h2 className="text-subheading">Accessibilité aux personnes handicapées</h2>
          <p className="text-content bold-italic">
            L&apos;application Défimédoc peut être téléchargée sur tablette afin d&apos;agrandir les caractères en cas
            de handicap visuel.
          </p>
          <p className="text-content">
            Pour tout autre handicap, merci de nous contacter au
            <em className="phone-number"> 06 30 26 94 73 </em>
            : nous étudierons avec vous toute possibilité d&apos;adaptation de la formation,
            en lien avec l&apos;Agefiph (Association de gestion du fonds pour l&apos;insertion des personnes
            handicapées).
          </p>
        </div>
        <div className="rules">
          <h2 className="text-subheading">Règlement intérieur</h2>
          <p className="text-content">
            Vous pouvez dès à présent télécharger le
            {' '}
            <button className="download"
              onClick={ downloadRules }
            >
              règlement intérieur
            </button>
            {' '}
            de nos parcours de formation.
          </p>
        </div>
        <div className="contact">
          <h2 className="text-subheading">Contact</h2>
          <p className="text-content">
            L’Equipe Défimédoc peut être contactée par mail
            {' '}
            <a href="mailto:contact@defimedoc.fr">contact@defimedoc.fr</a>
            {' '}
            ou téléphone au
            <em> 06 30 26 94 73</em>
            ,
            de 9h à 18h du lundi au vendredi.
            Réponse sous 24 heures ouvrées.
          </p>
        </div>
      </section>
    </Wrapper>
    <StatisticsBanner />
  </DpcLayout>
)

const Calendar = styled.div`
  grid-area: calendar-img;
  margin: auto;
`

const Notes = styled.div`
  grid-area: notes-img;
  margin: auto;
`

const Wrapper = styled(PageMainWrapper)`
  padding: ${sizes.heroesPaddingTop} 1rem 5rem;

  h2 {
    font-family: Montserrat;
  }

  .introduction {
    color: ${colors.darkBlue};
    text-align: center;

    p {
      margin-bottom: 1rem;
    }

    ul {
      padding-left: 0;
      margin-top: 0;
      margin-bottom: 2rem;
      font-family: Montserrat;
      list-style: none;
    }

    li {
      position: relative;
      padding-left: 2em;
      margin-bottom: 0.75rem;
      text-align: left;

      &::before {
        position: absolute;
        top: 0.25em;
        left: 0;
        width: 10px;
        height: 10px;
        content: "";
        background-color: ${colors.green};
        border-radius: 50%;
      }
    }
  }

  ${Separator} {
    margin: 3rem auto 2rem;
  }

  aside {
    padding: 1rem 1.75rem 0;
    color: ${colors.white};
    background-color: ${colors.hanBlue};
    border-radius: 10px;

    h2 {
      margin-top: 0;
      color: ${colors.white};
    }

    p {
      padding-bottom: 1rem;
      white-space: break-spaces;
    }

    em {
      color: ${colors.white};
    }

    .green {
      font-weight: bold;
      color: ${colors.lightGreen};
    }
  }

  em {
    font-weight: bold;
  }

  .bold-italic {
    font-style: italic;
    font-weight: bold;
  }

  .in-details {
    h2 {
      margin-bottom: 2rem;
    }

    ul {
      color: ${colors.darkBlue};
    }
  }

  .phone-number {
    white-space: nowrap;
  }

  .details-grid {
    display: grid;
    grid-template:
      "duration calendar-img"
      "empty1   delay"
      "means    notes-img"
      "empty2   supports"
      "tests    empty3"
      "empty4   evaluation"
      "prices   empty5"
      "empty6   accessibility"
      "rules    empty7"
      "empty8   contact" / 1fr 1fr;
    color: ${colors.darkBlue};

    > * {
      padding: 0 1rem;
    }

    ul {
      padding-left: 1.25rem;
    }

    li > div {
      display: flex;
    }

    li > div em {
      flex-shrink: 0;
      margin-right: 0.25rem;
    }

    p, li {
      margin: 1rem 0;
    }

    a, .download {
      font-weight: bold;
      color: ${colors.purple};
      text-decoration: underline;
    }

    &::after {
      grid-row: 1 / 11;
      grid-column-start: 2;
      pointer-events: none;
      content: "";
      border-left: 1px solid ${colors.purple};
    }
  }

  .delay {
    grid-area: delay;
  }

  .means {
    grid-area: means;
  }

  .supports {
    grid-area: supports;
  }

  .tests {
    grid-area: tests;
  }

  .evaluation {
    grid-area: evaluation;
  }

  .prices {
    grid-area: prices;
  }

  .accessibility {
    grid-area: accessibility;
  }

  .rules {
    grid-area: rules;
  }

  .contact {
    grid-area: contact;
  }

  @media (width <= 1050px) {
    .details-grid {
      grid-template:
        "duration"
        "calendar-img"
        "delay"
        "means"
        "supports"
        "notes-img"
        "tests"
        "evaluation"
        "prices"
        "accessibility"
        "rules"
        "contact" / 1fr;

      &::after {
        display: none;
      }
    }
  }
`

const prerequisites = [
  {
    key: '1',
    node: (
      <span>
        Le programme s’adresse aux
        <em> pharmaciens </em>
        d&apos;officine titulaires ou adjoints.
      </span>
    ),
  },
  {
    key: '2',
    node: (
      <span>
        Il n’y a pas de prérequis spécifiques.
      </span>
    ),
  },
  {
    key: '3',
    node: (
      <span>
        Le programme
        <em> Année 2 </em>
        s&apos;adresse aux pharmaciens ayant déjà eu à disposition la totalité des Quiz de
        l&apos;année 1, que ce soit en parcours de formation Année 1 ou en parcours libre sur l&apos;application
        Défimédoc.
      </span>
    ),
  },
]

const objectives = [
  {
    key: '1',
    node: (
      <span>
        Vérifier pour le domaine thérapeutique traité que la prescription répond à tous les critères de sécurisation de la dispensation.
      </span>
    ),
  },
  {
    key: '2',
    node: (
      <span>
        Identifier des modifications ou des adaptations si nécessaire, savoir conseiller le patient pour la prise optimale du traitement et faciliter l&apos;observance, anticiper les effets indésirables et donner les conseils adéquats pour éviter leur apparition ou limiter leurs conséquences.
      </span>
    ),
  },
  {
    key: '3',
    node: (
      <span>
        Faire preuve de vigilance sur les nouveautés en matière de bon usage des médicaments.
      </span>
    ),
  },
  {
    key: '4',
    node: (
      <span>
        Structurer une intervention pharmaceutique en utilisant la grille de la SFPC, savoir conduire une argumentation et échanger avec le prescripteur ou d&apos;autres professionnels de santé à partir de cette grille d&apos;intervention pharmaceutique.
      </span>
    ),
  },
]

export default Training
