export const truncate = (longstring, len) => {
  const ext = longstring.substring(longstring.lastIndexOf('.') + 1, longstring.length).toLowerCase()
  let filename = longstring.replace('.' + ext, '')
  if (filename.length <= len) {
    return longstring
  }
  filename = filename.substr(0, len) + (longstring.length > len ? '…' : '')

  return filename + '.' + ext
}

export const downloadPdf = (filename) =>
  fetch(`${process.env.REACT_APP_API_URL}/files/pdf/${filename}`)
    .then(res => res.blob())
    .then(data => {
      const a = document.createElement('a')
      a.href = window.URL.createObjectURL(data)
      a.download = filename
      a.click()
    })

export const downloadRules = () => downloadPdf('Règlement_intérieur_2022.pdf')
export const downloadDpcProgram1 = () => downloadPdf('Programme_DPC_Année1_Janvier_2025.pdf')
export const downloadDpcProgram2 = () => downloadPdf('Programme_DPC_Année2_Janvier_2025.pdf')
export const downloadIpTemplate = () => downloadPdf('Fiche-Intervention-pharmaceutique-SFPC.pdf')
export const downloadIpExample = () => downloadPdf('Exemples_Intervention_Pharmaceutique.pdf')
