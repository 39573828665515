import React, { useEffect, useCallback } from 'react'
import Cookies from 'js-cookie'
import { Buffer } from 'buffer'
import { login as LOGIN } from 'graphql/mutations/authentication'
import client from 'apolloClient'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router'
import { LoginMutation } from '@/types/graphql'
import { FORGOTTEN_PASSWORD_PATH, LANDING_PATH, REGISTER_PATH } from 'components/Router'
import styled from 'styled-components'
import { colors } from 'theme'
import { Button, FormInput, FormPasswordInput } from 'components/atoms'
import zod from 'zod'
import useForm from 'hooks/useForm'
import { invalidField, mandatoryField } from 'constants/form'
import { isEmail } from 'utils/regex'
import jwtPayloadExtractor from 'utils/jwtPayloadExtractor'
import { toast } from 'react-toastify'
import { InputType } from 'components/atoms/Input'
import Form from 'components/atoms/Form'

const schema = zod.object({
  email: zod.string().regex(isEmail, invalidField).min(1, mandatoryField),
  password: zod.string().min(1, mandatoryField),
})

const jwtSwitchTokenPayloadSchema = zod.object({
  email: zod.string().regex(isEmail, invalidField).min(1, mandatoryField),
  username: zod.string().min(1, mandatoryField),
  iat: zod.number(),
  exp: zod.number().optional(),
  admin: zod.string().optional(),
})

const initialState = {
  email: '',
  password: '',
}

type LoginForm = zod.infer<typeof schema>

const Login = () => {
  const [login] = useMutation<LoginMutation>(LOGIN)
  const navigate = useNavigate()

  const handleAutomaticLogin = useCallback(async (token: string) => {
    Cookies.remove('switchToken', { path: '/', domain: process.env.REACT_APP_DOMAIN })

    const payloadResult = jwtSwitchTokenPayloadSchema.safeParse(jwtPayloadExtractor(token))

    if (payloadResult.success) {
      const payload = payloadResult.data

      const expiresDate = payload.exp ? new Date(payload.exp * 1000) : 365
      Cookies.set('authToken', token, { expires: expiresDate })
    } else {
      console.error('JWT Payload schema validation failed', payloadResult.error)
    }

    navigate(LANDING_PATH)
    setTimeout(client.resetStore, 100)
  }, [navigate])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const redirected = urlParams.get('redirected')
    const tempSwitchToken = sessionStorage.getItem('tempSwitchToken')

    if (redirected && tempSwitchToken) {
      handleAutomaticLogin(tempSwitchToken)
      sessionStorage.removeItem('tempSwitchToken')
    }
  }, [handleAutomaticLogin])

  const loginSubmit = async (form: LoginForm) => {
    Cookies.set('basicToken', Buffer.from(`${form.email}:${form.password}`).toString('base64'))

    // Error handling is in apolloClient
    const loginResponse = await login()

    const authToken = loginResponse?.data?.authentication?.authToken
    const requireEmailValidation = loginResponse?.data?.authentication?.requireEmailValidation

    if (authToken) {
      Cookies.set('authToken', authToken, { expires: 365 })

      navigate(LANDING_PATH)
    } else if (requireEmailValidation){
      toast.error('Votre compte n\'est pas activé, car vous n\'avez pas encore validé votre adresse mail. Veuillez vérifier votre boîte mail et cliquer sur le lien de validation.')
    }

    setTimeout(client.resetStore, 100)
    Cookies.remove('basicToken')
  }

  const [data, errors, handleChange, handleSubmit, setForm] = useForm(initialState, schema, loginSubmit)

  useEffect(() => {
    const query = new URLSearchParams(window.location.search)
    const emailFromUrl = query.get('email')

    if (emailFromUrl) {
      setForm({ email: emailFromUrl, password: '' })

      toast.success('Email validé avec succès, vous pouvez vous connecter.', {
        position: 'top-center',
      })
    }
  }, [setForm])

  return (
    <>
      <Title>
        Connectez-vous à votre compte
      </Title>
      <Form
        id="login"
        noValidate
        onSubmit={ handleSubmit }
      >
        <FormInput
          error={ errors.email }
          isRequired
          label="Email"
          name="email"
          type={ InputType.EMAIL }
          value={ data.email }
          onChangeText={ handleChange }
        />
        <FormPasswordInput
          error={ errors.password }
          isRequired
          label="Mot de passe"
          name="password"
          value={ data.password }
          onChangeText={ handleChange }
        />
      </Form>
      <ForgottenPassword
        onClick={ () => navigate(FORGOTTEN_PASSWORD_PATH) }
      >
        mot de passe oublié ?
      </ForgottenPassword>
      <Button
        form="login"
        size="large"
        type="submit"
        variant="primary"
      >
        Je me connecte
      </Button>
      <RegisterTip>
        Vous n’avez pas encore de compte ?
      </RegisterTip>
      <Register
        variant="tertiary"
        onClick={ () => navigate(REGISTER_PATH) }
      >
        {'S\'inscrire'}
      </Register>
    </>
  )
}

const Title = styled.h1`
  font-family: 'Brother 1816';
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 900;
  line-height: 2.7rem;
  color: ${colors.midnightBlue};
`

const ForgottenPassword = styled.button`
  flex-shrink: 0;
  align-self: flex-end;
  margin-bottom: 2rem;
  font-family: Montserrat;
  font-size: 0.875rem;
  font-style: italic;
  font-weight: normal;
  color: ${colors.lightGreen};
`

const RegisterTip = styled.span`
  margin-top: 1.5rem;
  font-family: 'Brother 1816';
  font-style: normal;
  font-weight: normal;
  color: ${colors.midnightBlue};
  text-align: center;
`

const Register = styled(Button)`
  align-self: center;
  margin-top: 1rem;
`

export default Login
