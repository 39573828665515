import React from 'react'
import styled from 'styled-components'
import { colors } from 'theme'
import {
  Separator,
} from 'components/landing'
import {
  CheckList,
} from 'components/atoms'
import {
  FoldableFAQList,
  DpcCards,
} from '../index'
import { PageMainWrapper } from '../style'

const Main = () => {
  return (
    <Wrapper>
      <p className="text-heading introduction">
        En complément de son application,
        <br />
        Défimédoc vous propose un premier parcours de formation DPC afin d’
        <em>améliorer vos pratiques</em>
        .
      </p>
      <div>
        <p>Date de mise à jour: Octobre 2024</p>
        <h3 className="text-subheading">La formation comprend :</h3>
        <CheckList items={ checkItems } />
        <FoldableFAQList />
        <Separator />
        <h2 className="text-heading">Le parcours de formation</h2>
        <p className="text-content bottom-block">
          La formation Année 2 ne peut être réalisée que lorsque les Quiz de l&apos;Année 1 ont déjà été validés.
          <br />
          Par défaut, vous serez inscrit à la formation Année 1 (voir le Programme pour le détail des thématiques médicamenteuses abordées).
        </p>
        <DpcCards />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled(PageMainWrapper)`
  padding: 0 1rem 3rem;
  color: ${colors.darkBlue};

  em {
    font-style: normal;
    color: ${colors.hanBlue};
  }

  h2 {
    text-align: center;
  }

  h3 {
    margin: 6rem 0 3rem;
    color: ${colors.purple};
  }

  p.bottom-block {
    text-align: center;

    em {
      font-weight: bold;
      color: ${colors.darkBlue};
    }
  }

  li {
    text-align: left;
  }

  ${Separator} {
    margin: auto;
  }

  @media (width <= 1050px) {
    .cards-wrapper {
      flex-direction: column;
      align-items: center;

      > *:first-child {
        margin-bottom: 2rem;
      }
    }
  }
`

const checkItems = [
  {
    key: '1',
    node: (
      <span>
        La mise à disposition de
        <em> 2 nouveaux Quiz chaque semaine </em>
        pendant la durée de votre formation, via l&apos;application mobile Défimédoc.
      </span>
    ),
  },
  {
    key: '2',
    node: (
      <span>
        Une présentation en vidéo du concept d&apos;
        <em>Intervention Pharmaceutique (IP)</em>
        , en lien avec les Quiz Défimédoc. Vous devrez rédiger et déposer au minimum une Intervention Pharmaceutique
        sur votre Espace Formation.
      </span>
    ),
  },
  {
    key: '3',
    node: (
      <span>
        <em>Deux tests de positionnement pré et post-formation</em>
        , permettant de mesurer l&apos;amélioration des connaissances et des compétences. Le premier test doit être
        passé avant de débuter les Quiz, le deuxième dans les 8 jours qui suivent la fin des Quiz.
      </span>
    ),
  },
  {
    key: '4',
    node: (
      <span>
        L&apos;assistance technique et pédagogique tout au long du parcours de formation, par mail ou téléphone.
      </span>
    ),
  },
  {
    key: '5',
    node: (
      <span>
        La mise à disposition des
        <em> Fiches de synthèse l&apos;Essentiel </em>
        au format pdf à la fin de la formation.
      </span>
    ),
  },
]

export default Main
