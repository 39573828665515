import React, { FC } from 'react'
import styled from 'styled-components'
import { colors } from 'theme'

type Props = {
    time: string;
}

const DpcCardContent: FC<Props> = ({ time }) => (
  <ContentList>
    <li>
      <Bold>
        { time }
        h de formation
      </Bold>
    </li>
    <li><Bold>30 Quiz d&apos;apprentissages</Bold></li>
    <li>
      Accès aux
      <Bold> 15 fiches l&apos;Essentiel</Bold>
    </li>
    <li>
      Initiation aux
      <Bold> Interventinons Pharmaceutiques</Bold>
    </li>
    <li>Tests pré et post-formation</li>
  </ContentList>
)

const Bold = styled.b`
  font-weight: 700;
  color: ${colors.darkBlue};
`

const ContentList = styled.ul`
  padding-left: 1.5rem;
  margin-top: 0;
`

export default DpcCardContent
