import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from 'theme'
import {
  FoldableFAQ,
} from './index'

const FoldableFAQList = () => {
  const [openedIndex, setOpenedIndex] = useState(null)

  const onToggle = (index) => () => {
    if (index === openedIndex) {
      setOpenedIndex(null)

      return
    }

    setOpenedIndex(index)
  }

  return (
    <Wrapper>
      { FAQs.map((faq, index) =>
        <FoldableFAQ
          key={ faq.question }
          opened={ openedIndex === index }
          onToggle={ onToggle(index) }
          { ...faq }
        />,
      ) }
    </Wrapper>
  )
}

const Wrapper = styled.section`
  margin-bottom: 3rem;
`

const FAQAnswerList = styled.ul`
  padding-left: 1.5rem;
`

const List = styled.li`
  margin: 1rem 0;
`

const Bold = styled.em`
  font-weight: bold;
  color: ${colors.darkBlue};
`

const FAQs = [
  {
    question: 'Puis-je m\'inscrire au parcours de formation DPC si je suis déjà abonné individuel ou si j\'ai déjà été abonné à Défimédoc ?',
    answer: (
      <FAQAnswerList>
        <List>
          Il est possible de s&apos;inscrire à un parcours de formation DPC si l&apos;on est déjà abonné à Défimédoc
          individuellement.
        </List>
        <List>
          Vous serez
          <Bold> automatiquement inscrit aux Quiz &quot;Année 1&quot; ou &quot;Année 2&quot; </Bold>
          selon votre
          ancienneté dans l&apos;application.
        </List>
        <List>
          Les
          <Bold> frais d&apos;abonnements en cours seront suspendus </Bold>
          pendant la durée de la formation. Si l&apos;abonnement en cours est un abonnement annuel, un avoir sera
          établi, à valoir sur la prochaine période d&apos;abonnement payante.
        </List>
      </FAQAnswerList>
    ),
  },
  {
    question: 'Puis-je inscrire un membre de mon équipe au parcours de formation DPC si je suis déjà abonné en' +
      ' abonnement Team ?',
    answer: (
      <FAQAnswerList>
        <List>
          Si vous avez déjà un abonnement d&apos;Equipe Défimédoc, vous pouvez inscrire l&apos;un des membres de
          l&apos;Equipe (pharmacien adjoint) à un parcours de formation.
        </List>
        <List>
          Le coût de la licence mensuelle
          <Bold> sera diminué au prorata </Bold>
          pendant la durée du parcours de formationDPC.
        </List>
        <List>
          S&apos;il s&apos;agit d&apos;un abonnement Team annuel, un avoir sera fait au proporata du temps non
          consommé, à valoir sur la prochaine période d&apos;abonnement payante.
        </List>
      </FAQAnswerList>
    ),
  },
  {
    question: 'Puis-je suivre ma formation sur ordinateur ?',
    answer: (
      <FAQAnswerList>
        <List>
          Les Quiz et Fiches l&apos;Essentiel durant le parcours de formation
          <Bold> ne sont disponibles que sur l&apos;application Défimédoc.</Bold>
        </List>
        <List>
          Le téléchargement de l&apos;application est gratuit sur AppStore ou Playstore. Vous aurez automatiquement
          accès aux Quiz, à raison de 2 nouveaux Quiz par semaine.
        </List>
        <List>
          Les Quiz peuvent être faits sur
          <Bold> smartphone ou tablette.</Bold>
        </List>
      </FAQAnswerList>
    ),
  },
  {
    question: 'Puis-je interrompre ma formation pendant mes congés ?',
    answer: (
      <FAQAnswerList>
        <List>
          Tous les Quiz
          <Bold> restent disponibles </Bold>
          dans la colonne &quot;A faire&quot; de l&apos;application, s&apos;ils ne sont pas faits immédiatement.
        </List>
        <List>
          Vous pouvez donc interrompre vos Quiz pendant vos congés, sous réserve de valider les Quiz non faits dans le temps restant.
        </List>
        <List>
          La durée du parcours de formation n&apos;est pas rallongée en fonction de votre temps d&apos;interruption.
        </List>
      </FAQAnswerList>
    ),
  },
  {
    question: 'Puis-je refaire plusieurs fois chaque Quiz ?',
    answer: (
      <FAQAnswerList>
        <List>
          Tous les Quiz peuvent être
          <Bold> refaits autant de fois que désiré.</Bold>
        </List>
        <List>Ils sont validés pour un score ≥ 70/100.</List>
        <List>Même une fois validés, ils peuvent être refaits à volonté.</List>
      </FAQAnswerList>
    ),
  },
  {
    question: 'Que dois-je faire pour valider ma formation DPC ?',
    answer: (
      <FAQAnswerList>
        <List>
          Tous les Quiz doivent être faits
          <Bold> au minimum une fois</Bold>
          . Il est vivement conseillé de valider tous les Quiz (score ≥ 70 points).
        </List>
        <List>
          Le temps passé sur les Quiz est
          <Bold> enregistré automatiquement </Bold>
          et sera
          <Bold> transmis à l&apos;ANDPC </Bold>
          pour validation de la formation DPC.
        </List>
        <List>
          Vous devez également visionner la vidéo
          <Bold> &quot;Tracer ses interventions pharmaceutiques pertinentes&quot; </Bold>
          (10 minutes) proposée sur votre Espace Apprenant.
        </List>
        <List>
          Après avoir visionné cette vidéo, vous devrez déposer sur votre Espace Apprenant
          <Bold> au minimum une Intervention Pharmaceutique </Bold>
          (Description du contexte, proposition d&apos;intervention, justification) en lien avec l&apos;un des thèmes
          des Quiz.
        </List>
        <List>
          Un
          <Bold> Pre-Test et un Post-Test </Bold>
          permettront de mesurer votre progression.
        </List>
      </FAQAnswerList>
    ),
  },
]

export default FoldableFAQList
