import React from 'react'
import { colors } from 'theme'
import styled from 'styled-components'
import FooterItem from 'components/FooterItem'

const Footer = () => {
  return (
    <Wrapper>
      <CopyRights>
        © DÉFIMÉDOC, Tous droits réservés
      </CopyRights>
      <nav>
        <Links>
          <FooterItem
            href="/terms-of-use"
            separator={ false }
          >
            CGV / CGU
          </FooterItem>
          <FooterItem
            href="/legal-notice"
          >
            Mentions légales
          </FooterItem>
          <FooterItem href="/privacy-policy">
            Politique de protection des données personnelles
          </FooterItem>
        </Links>
      </nav>
    </Wrapper>
  )
}

const Wrapper = styled.footer`
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  color: ${colors.cornflowerBlue};
  user-select: none;
  border-top: 1px solid ${colors.cornflowerBlue};
`

const Links = styled.ul`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding-left: 0;
  margin: 0;
  font-size: 0.625rem;
`

const CopyRights = styled.small`
  font-family: Montserrat;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
`

export default Footer
