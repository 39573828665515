import React from 'react'
import { Helmet } from 'react-helmet'
import CartProvider from 'context/Cart'
import ModalProvider from 'context/Modal'
import 'react-toastify/dist/ReactToastify.css'
import GlobalStyle from 'assets/GlobalStyle'
import client from 'apolloClient'
import ToastContainer from 'components/ToastContainer'
import { isSubdomain } from 'utils/origin'
import PropTypes from 'prop-types'
import CookieBanner from 'components/CookieBanner'
// Needed ! See https://github.com/styled-components/styled-components/issues/1593
import 'assets/fonts.css'
import 'logService'

const App = ({ ApolloProvider, Router }) => {
  if (null == process.env.REACT_APP_STRIPE_KEY) {
    throw new Error('REACT_APP_STRIPE_KEY is not defined')
  }

  return (
    <>
      { isSubdomain() &&
        <Helmet>
          <meta content="noindex, nofollow"
            name="robots"
          />
          <meta content="noindex"
            name="googlebot"
          />
        </Helmet>
      }
      <GlobalStyle />
      <ToastContainer />
      <ApolloProvider client={ client }>
        <ModalProvider>
          <CartProvider>
            <Router />
          </CartProvider>
        </ModalProvider>
      </ApolloProvider>
      <CookieBanner />
    </>
  )
}

App.propTypes = {
  ApolloProvider: PropTypes.func.isRequired,
  Router: PropTypes.func.isRequired,
}

export default App
