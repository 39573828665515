import React, { FC } from 'react'
import styled from 'styled-components'
import {
  Card,
} from 'components/atoms'
import { downloadDpcProgram1, downloadDpcProgram2 } from 'utils/file'
import DpcCardHeaderIcon from 'components/dpc/DpcCardHeaderIcon'
import DpcCardContent from 'components/dpc/DpcCardContent'

const DpcCards: FC = () => (
  <Wrapper>
    <Card
      buttonLabel="Télécharger le programme"
      buttonOnClick={ downloadDpcProgram1 }
      content={ <DpcCardContent time="8" /> }
      headerBottom={ <DpcCardHeaderIcon /> }
      headerMiddle={ <Center>Repérer le risque iatrogène et optimiser les interventions pharmaceutiques - Année 1</Center> }
      headerTop='Session du 6 janvier 2025 au 2 mai 2025'
    />
    <Card
      buttonLabel="Télécharger le programme"
      buttonOnClick={ downloadDpcProgram2 }
      content={ <DpcCardContent time="9" /> }
      headerBottom={ <DpcCardHeaderIcon /> }
      headerMiddle={ <Center>Repérer le risque iatrogène et optimiser les interventions pharmaceutiques - Année 2</Center> }
      headerTop='Session du 6 janvier 2025 au 2 mai 2025'
    />
  </Wrapper>
)

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 3rem;

  @media (width <= 600px) {
    flex-direction: column;
    align-items: center;

    > *:first-child {
      margin-bottom: 2rem;
    }
  }
`

const Center = styled.div`
  text-align: center;
`

export default DpcCards
