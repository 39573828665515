import React, { FC } from 'react'
import styled from 'styled-components'
import { Icon } from 'components/atoms'

const DpcCardHeaderIcon: FC = () => (
  <HeaderBottom>
    <StyledIcon name="time" />
    <span>4 mois</span>
  </HeaderBottom>
)

const StyledIcon = styled(Icon)`
  margin-right: 0.5rem;
  filter: invert();
`

const HeaderBottom = styled.div`
  display: flex;
  align-items: center;
`

export default DpcCardHeaderIcon
