import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { colors } from 'theme'

const HowToSubscribe = () => (
  <Wrapper>
    <section className="text-description">
      <p className="text-description">
        Mon Espace Formation est réservé aux professionnels de santé inscrits aux parcours de DPC proposés par
        Défimédoc.
        <br />
        Pour vous inscrire au premier parcours DPC proposé par Défimédoc :
      </p>
      <ul>
        <li className="text-description">
          pour les pharmaciens titulaires : inscription sur
          {' '}
          <a href="https://mondpc.fr">mondpc.fr</a>
          {' '}
          référence de la formation : 96662200001
        </li>
        <li className="text-description">
          pour les pharmaciens adjoints (inscription par la Pharmacie ou à titre individuel) : envoyez-nous un mail à
          {' '}
          <a href="mailto:contact@defimedoc.fr">contact@defimedoc.fr</a>
          {' '}
          pour recevoir les conditions d&apos;inscriptions (coût, prise en charge).
        </li>
      </ul>
      <p className="text-description">
        Vous pouvez télécharger le programme DPC sur la page
        {' '}
        <Link to="/dpc/training">La Formation</Link>
        .
      </p>

      <p className="text-description signature">
        <em>
          L&apos;Equipe Défimédoc
        </em>
        <br />
        contact@defimedoc.fr
        <br />
        06 30 26 94 73
      </p>
    </section>
  </Wrapper>
)

const Wrapper = styled.main`
  margin-top: 3rem;

  a {
    font-weight: bold;
    color: ${colors.purple};
    text-decoration: underline;
  }

  .signature {
    line-height: 25px;
  }
`

export default HowToSubscribe
